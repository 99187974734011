import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import {Helmet} from 'react-helmet';
import Page from '../components/Page';
import {graphql} from 'gatsby';

export default function Faq({data: {allFaq, pageSeo}}) {
	return <Page>
		<Helmet>
			<meta charSet="utf-8" />
			<title>{pageSeo.PageTitle}</title>
			<meta name="description" content={pageSeo.Description} />

			<link rel="canonical" href="https://www.qxp.com.au/faq" />
		</Helmet>

		<Container fluid>
			<Row>
				<Col>
					<h3>FAQ</h3>
					<Accordion defaultActiveKey="0">
						{allFaq.nodes
							.map((faq, index) => <FAQ key={index} faq={faq} index={index} />)}
					</Accordion>
				</Col>
			</Row>
		</Container>
	</Page>;
}

function FAQ({faq, index}) {
	return <Card>
		<Accordion.Toggle as={Card.Header} eventKey={`${index}`} style={{cursor: 'pointer'}}>
			<h4>{faq.Question}</h4>
		</Accordion.Toggle>
		<Accordion.Collapse eventKey={`${index}`}>
			<Card.Body>
				<h5>{faq.Answer}</h5>

				{!!faq.Image?.URL && <img
					className="d-block mw-100 mh-100"
					src={faq.Image.URL}
					alt={faq.AltText}
				/>}

			</Card.Body>
		</Accordion.Collapse>
	</Card>;
}

export const query = graphql`
    query {
		allFaq(filter: {published: {eq: true}}) {
			nodes {
				Question
				Answer
				id
				published
				Image {
					URL
					hash
					name
				}
				AltText 	
			}
		}
		pageSeo(id: {eq: "6088f7a7-0c77-cdf9-74db-e8b85ba044be"}) {
			PageTitle
			Description
		}
    }
`;
